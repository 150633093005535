
import { defineComponent, onMounted, reactive } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import { Field } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ApiService from "@/core/services/ApiService";
import { hideModal } from "@/core/helpers/dom";
import { useToast } from "vue-toastification";
import { ref } from "yup";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import moment from "moment";
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Multiselect from "@vueform/multiselect";
moment.locale("id");

import { Modal } from "bootstrap";

export default defineComponent({
  name: "kt-widget-11",
  components: {
    Field,
    Loading,
    Multiselect,
  },
  props: {
    widgetClasses: String,
  },

  data() {
    return {
      tableData: [
        {
          confirmation: "",
          confirmed_time: "",
          createTime: "",
          creatorId: "",
          creator_name: "",
          deleteBy: null,
          deleteTime: null,
          editorId: "",
          initialContractStatus: null,
          isDelete: false,
          legal_file: null,
          legal_id: null,
          legal_status: null,
          note: "",
          progress_responsible: "",
          school_name: "",
          progress_status: null,
          requested_time: "",
          sales_area_name: null,
          schoolId: "",
          // school_name:  "",
          updateTime: "",
          _id: "",
        },
      ],
      listSekolah: new Array(),
      sortkonfirmasi: true,
      listConfirmation: [
        {
          label: "Menunggu Konfirmasi",
          value: "waiting",
        },
        {
          label: "Ditolak",
          value: "reject",
        },
        {
          label: "Disetujui",
          value: "approve",
        },
      ],
      listStatus: [
        {
          label: "Disetujui",
          value: "approve",
        },
        {
          label: "Ditolak",
          value: "reject",
        },
        {
          label: "Menunggu Konfirmasi",
          value: "",
        },
      ],
      listType: [],
      isLoading: false,
      fullPage: true,
      isColor: "#007BFF",
      isBackgroundColor: "#A3A3A3",
      isOpacity: 0.5,

      paging: {
        page: 0,
        totalPages: 0,
        size: 10,
        totalElements: 0,
        perPageOptions: [10, 25, 50, 100],
      },

      items: {
        errornote: "",
        errorconfirmation: "",
      },

      name: "",
      schoolId: "",
      schoolName: "",
      confirmation: "",
      roleuser: "",
      filterConfir: "&sort=updateTime&dir=-1",
      itemId: "",

      formDetail: {
        creator_name: "",
        requested_time: "",
        sales_area_name: "",
        progress_responsible: "",
        confirmation: "",
        note: "",
        initialContractStatus: "",
        progress_status: "",
        school_name: "",
      },

      statusFromNotif: "false",
      valueIDLocalStorage: "",

      showFilter: false,
      timer: 0,
    };
  },

  beforeMount() {
    // store.dispatch(Actions.VERIFY_AUTH);

    this.roleuser = JSON.parse(localStorage.getItem("user_account")!).role;
    this.schoolId = JSON.parse(localStorage.getItem("sekolahId")!);
    this.schoolName = JSON.parse(localStorage.getItem("schoolName")!);

    this.statusFromNotif = localStorage.getItem(
      "statusKonfirmasiLegalfromNotif"
    )!;
    if (this.statusFromNotif) {
      if (this.statusFromNotif == "true") {
        console.log("true from notif");
        // this.startDate = localStorage.getItem("tglKonfirmasiLegalfromNotif")!;
        this.valueIDLocalStorage = localStorage.getItem(
          "idKonfirmasiLegalfromNotif"
        )!;

        localStorage.removeItem("statusKonfirmasiLegalfromNotif");
        localStorage.removeItem("idKonfirmasiLegalfromNotif");
        localStorage.removeItem("tglKonfirmasiLegalfromNotif");
      }
    }

    this.getData(this.paging.size, this.paging.page, this.filterConfir);
    this.getListSekolah();
    setCurrentPageBreadcrumbs("Konfirmasi Legal", ["Data"]);
  },
  mounted() {
    console.log(this.statusFromNotif);
    if (this.statusFromNotif == "true") {
      if (this.valueIDLocalStorage) {
        this.getDetailModal(this.valueIDLocalStorage);
      }
    }
  },
  computed: {
    to() {
      let highBound = this.from + this.paging.size;
      if (this.paging.totalElements < highBound) {
        highBound = this.paging.totalElements;
      }
      return highBound;
    },
    from() {
      return this.paging.size * this.paging.page;
    },
  },

  methods: {
    sorting(data) {
      var acoba = JSON.stringify(this.listType);
      var sss = JSON.parse(acoba);
      let seleksi = reactive([]);
      let tes = [sss];
      console.log(data);
      // var sort = data ? "-1" : "1"
      var newsorting = `sort=updateTime&dir=-1&sortConfirmation=${data}`;
      this.confirmation = newsorting;
      data == true
        ? (this.sortkonfirmasi = false)
        : (this.sortkonfirmasi = true);

      this.getData(this.paging.size, this.paging.page, newsorting);
      // tes[0].forEach(function (el, i) {
      //   if (el.confirmation == null) {
      //     el.confirmation = "waiting"
      //   }
      // })

      // if (data == true) {
      //   this.sortkonfirmasi = !this.sortkonfirmasi
      //   let bs = tes[0].sort((a, b) => b.confirmation.localeCompare(a.confirmation));
      //   console.log(tes[0], "tes")
      //   this.tableData = bs
      // } else {
      //   this.sortkonfirmasi = !this.sortkonfirmasi
      //   let bs = tes[0].sort((a, b) => a.confirmation.localeCompare(b.confirmation));
      //   console.log(tes[0], "tes")
      //   this.tableData = bs
      // }
    },
    getData(size, page, request) {
      this.isLoading = true;
      let konfirmasi = "";
      let search1 = "";
      if (this.schoolName) {
        search1 = "name=" + this.schoolName + "&";
      } else {
        search1;
      }
      if (this.confirmation) {
        konfirmasi = "confirmation=" + this.confirmation + "&";
      } else {
        konfirmasi;
      }

      this.paging.page = page;

      ApiService.getWithoutSlug(
        "crmv2/main_legal/confirmation/get_all_confirmation_request?" +
          search1 +
          // konfirmasi+
          "page=" +
          page +
          "&size=" +
          size +
          `&${request}`
      )
        .then(({ data }) => {
          this.tableData = data.content;
          this.listType = data.content;
          this.paging.size = data.size;
          this.paging.totalElements = data.totalElements;
          this.paging.totalPages = data.totalPages;
          this.isLoading = false;
        })
        .catch(({ response }) => {
          this.isLoading = false;
        });
    },

    getListSekolah() {
      ApiService.getWithoutSlug("crmv2/main_school/school/school").then(
        ({ data }) => {
          this.listSekolah = data;
          localStorage.setItem("listSchool", JSON.stringify(data));
        }
      );
    },

    pilihSekolah(event) {
      var namaSekolah = "";
      if (event) {
        this.schoolId = event;
        let school: any = this.listSekolah.find(
          (item: any) => item._id == this.schoolId
        );
        namaSekolah = school.name;
        this.schoolName = school.name;
      } else {
        this.schoolId = "";
      }
      // var upd_obj = this.listSekolah.findIndex((obj => obj.name == this.schoolId));
      // var sekolahId = this.listSekolah[upd_obj]._id;

      localStorage.setItem("schoolName", JSON.stringify(namaSekolah));
      localStorage.setItem("sekolahId", JSON.stringify(event));
      this.getData(this.paging.size, this.paging.page, this.filterConfir);
    },

    submitAdd() {
      if (
        this.formDetail.confirmation === "" ||
        this.formDetail.confirmation === null
      ) {
        this.items.errorconfirmation = "Status Permintaan Wajib Diisi";
      } else {
        this.items.errornote = "";
        this.items.errorconfirmation = "";

        this.isLoading = true;
        const toast = useToast();

        // Send login request
        ApiService.PutMethodWithoutData(
          "crmv2/main_legal/confirmation/confirm_request/" +
            this.itemId +
            "?note=" +
            this.formDetail.note +
            "&confirmation=" +
            this.formDetail.confirmation
        )
          .then((res) => {
            toast.success("Berhasil Update Detail Konfirmasi Legal");
            this.isLoading = false;
            location.reload();
          })
          .catch((e) => {
            if (e.response.status == 401) {
              toast.error(e.response.data.detail);
              this.$router.push("/sign-in");
              this.isLoading = false;
            } else {
              toast.error(e.response.data.detail);
              this.isLoading = false;
            }
            this.isLoading = false;
          });
      }
    },

    dateTime2(value) {
      return moment(value).format("DD MMMM YYYY");
    },

    range(min, max) {
      const arr: any[] = [];
      for (let i = min; i <= max; i++) {
        arr.push(i);
      }
      return arr;
    },
    getDetailModal(valueID) {
      this.itemId = valueID;
      this.isLoading = true;
      const toast = useToast();

      ApiService.getWithoutSlug(
        "crmv2/main_legal/confirmation/get_confirmation_request_detail/" +
          valueID
      )
        .then((res) => {
          var tes = moment(res.data.requested_time).format("DD MMMM YYYY");
          var progressStatus = "";
          if (res.data.progress_status == "Approve") {
            progressStatus = "Disetujui";
          } else if (res.data.progress_status == "Cancel") {
            progressStatus = "Ditolak";
          } else if (
            res.data.progress_status == "Pending" ||
            res.data.progress_status == "Waiting"
          ) {
            progressStatus = "Menunggu";
          } else if (res.data.progress_status == "Process") {
            progressStatus = "Proses";
          } else if (res.data.progress_status == null) {
            progressStatus = "Leads";
          }
          this.formDetail.creator_name = res.data.creator_name;
          this.formDetail.requested_time = tes;
          this.formDetail.school_name = res.data.school_name;
          this.formDetail.progress_responsible = res.data.progress_responsible;
          this.formDetail.sales_area_name =
            res.data.sales_area_name || "Sekolah Belum Memiliki Sales";
          this.formDetail.confirmation = res.data.confirmation;
          this.formDetail.note = res.data.note;
          this.formDetail.initialContractStatus = res.data.legal_status;
          this.formDetail.progress_status = progressStatus;

          this.isLoading = false;
        })
        .catch((e) => {
          if (e.response.status == 401) {
            toast.error(e.response.data.detail);
            this.$router.push("/sign-in");
            this.isLoading = false;
          } else {
            toast.error(e.response.data.detail);
            this.isLoading = false;
          }
        });

      const modal = new Modal(
        document.getElementById("kt_modal_confirm_legal")
      );
      modal.show();
    },
    getId(index, item) {
      this.itemId = item._id;

      this.isLoading = true;
      const toast = useToast();

      ApiService.getWithoutSlug(
        "crmv2/main_legal/confirmation/get_confirmation_request_detail/" +
          item._id
      )
        .then((res) => {
          var tes = moment(res.data.requested_time).format("DD MMMM YYYY");
          var progressStatus = "";
          if (res.data.progress_status == "Approve") {
            progressStatus = "Disetujui";
          } else if (res.data.progress_status == "Cancel") {
            progressStatus = "Ditolak";
          } else if (
            res.data.progress_status == "Pending" ||
            res.data.progress_status == "Waiting"
          ) {
            progressStatus = "Menunggu";
          } else if (res.data.progress_status == "Process") {
            progressStatus = "Proses";
          } else if (res.data.progress_status == null) {
            progressStatus = "Leads";
          }
          this.formDetail.creator_name = res.data.creator_name;
          this.formDetail.requested_time = tes;
          this.formDetail.school_name = res.data.school_name;
          this.formDetail.progress_responsible = res.data.progress_responsible;
          this.formDetail.sales_area_name =
            res.data.sales_area_name || "Sekolah Belum Memiliki Sales";
          this.formDetail.confirmation = res.data.confirmation;
          this.formDetail.note = res.data.note;
          this.formDetail.initialContractStatus = res.data.legal_status;
          this.formDetail.progress_status = progressStatus;

          this.isLoading = false;
        })
        .catch((e) => {
          if (e.response.status == 401) {
            toast.error(e.response.data.detail);
            this.$router.push("/sign-in");
            this.isLoading = false;
          } else {
            toast.error(e.response.data.detail);
            this.isLoading = false;
          }
        });
    },

    changePage(item) {
      const page = item - 1;
      this.getData(this.paging.size, page, this.filterConfir);
    },
    pilihStatus(event) {
      // this.schoolId = event;
      if (event) {
        this.confirmation = event;
      } else {
        this.confirmation = "";
      }
      // this.getQtyStudent();
      // localStorage.setItem("sekolahId", JSON.stringify(event));
      // this.getData(this.paging.size, this.paging.page);
    },
    nextPage() {
      const page = this.paging.page + 1;
      this.getData(this.paging.size, page, this.filterConfir);
    },
    prevPage() {
      const page = this.paging.page - 1;
      this.getData(this.paging.size, page, this.filterConfir);
    },
    changePageSize() {
      const page = this.paging.page;
      this.getData(this.paging.size, page, this.filterConfir);
    },

    getExtensionFile(val) {
      if (val) {
        const myArray = val.split(".");
        return myArray[1];
      } else {
        return "-";
      }
    },

    //event
    eventDelaySearch(e) {
      clearTimeout(this.timer); //this timer 0 - set 0 atau clear jika ada input lagi

      this.timer = setTimeout(() => {
        const page = this.paging.page;
        // this.getData(this.paging.size, page);
      }, 1000);
    },
    showHideFilter() {
      this.showFilter = !this.showFilter;
    },
  },
});
